import { useRef, useEffect, useState } from "react";
import logo from "./logo.svg";
import "./App.css";

function App() {
  const [holding, setHolding] = useState(false);
  const [error, setError] = useState(false);
  const [result, setResult] = useState(false);
  const [finalizing, setFinalizing] = useState(false);
  const holdStart = useRef(0);
  const holdTimer = useRef(null);
  const finalizeTimer = useRef(null);

  useEffect(() => {
    if (holding && !finalizing) {
      window.clearTimeout(holdTimer.current);
      window.clearTimeout(finalizeTimer.current);
      holdTimer.current = window.setTimeout(() => {
        setResult(true);
      }, 8000);
      finalizeTimer.current = window.setTimeout(() => {
        setFinalizing(true);
      }, 4500);
    }
    return () => {
      window.clearTimeout(holdTimer.current);
      window.clearTimeout(finalizeTimer.current);
    };
  }, [holding]);

  useEffect(() => {
    if (error) {
      setTimeout(() => {
        setError(false);
      }, 3000);
    }
  }, [error]);

  return (
    <button
      className="App"
      onPointerUp={() => {
        if (holding) {
          const holdDuration = performance.now() - holdStart.current;
          if (holdDuration >= 5000) {
            // setResult(true);
          } else {
            setResult(false);
            setFinalizing(false);
            setError(true);
            setHolding(false);
          }
        }
      }}
      onPointerDown={() => {
        if (!error && !result && !finalizing) {
          holdStart.current = performance.now();
          setHolding(true);
        }
      }}
    >
      <div
        className={
          holding && !result && !finalizing
            ? "message message--held"
            : "message"
        }
      >
        <p>Please Remain Still</p>
      </div>

      <div
        className={
          holding && !result && finalizing
            ? "message message--finalizing message--held"
            : "message message--finalizing"
        }
      >
        <p>Processing Results</p>
      </div>

      <div className={result ? "result" : "result result--hide"}>
        <span>
          <p>Assessment Complete</p>
          <p>
            <span>You</span> are a <span>Dweeb</span>
          </p>
        </span>
      </div>

      <div className={error ? "error" : "error error--hide"}>
        <span>
          <p>
            Assessment <span>failed</span>
          </p>
          <p>Please try again</p>
        </span>
      </div>
      <div
        className={
          holding || error
            ? error
              ? "cat cta--hidden cta--error"
              : "cta cta--hidden"
            : "cta"
        }
      >
        <h1 className="welcome">
          Are <span>You</span> a <span>Dweeb</span>?
        </h1>
        <h2>Use the button to begin your assessment</h2>
        <div className="button">Hold to Begin</div>
      </div>
      <div
        className={
          result
            ? "filler filler--done"
            : holding
            ? "filler filler-filled"
            : "filler"
        }
      ></div>
    </button>
  );
}

export default App;
